const amenities = [
    {name:'Aire Acondicionado'},
    {name:'Aire Frio/Calor'},
    {name:'Alarma'},
    {name:'Caldera'},
    {name:'Calefacción'},
    {name:'Cámaras de seguridad'},
    {name:'Cierre perimetral'},
    {name:'Hidromasaje'},
    {name:'Hogar a leña'},
    {name:'Jacuzzi'},
    {name:'Losa radiante'},
    {name:'Parrilla'},
    {name:'Placares'},
    {name:'Radiador'},
    {name:'Riego automatizado'},
    {name:'Sauna'},
    {name:'Tranquera'},
]

export default amenities